import React, { useState } from "react";
import { DateRangePicker } from "@shared/eurosport-ui";
import dayjs from "dayjs";
import {
  Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { Card, Grid } from "@mui/material";
import SearchIcon from "@shared/eurosport-ui/icons/Search";
import { Box } from "@mui/system";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const Menu = (props: any) => {
  const timeZone = "Europe/Paris";

  const [startDate, setStartDate] = useState<dayjs.Dayjs>(dayjs(props.Start));
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(dayjs(props.End));
  const [endDateString, setEndDateString] = useState<string>(
    dayjs.tz(endDate, timeZone).utc().format("YYYY-MM-DD")
  );
  const [Search] = useState(props.Search);
  const [hubSelected, setHubSelected] = useState<string[]>([]);
  const hubs = ["Paris", "London"];

  let timer: any = null;

  function updateFilterDate(start: string | null, end: string | null): void {
    const newStartDate: dayjs.Dayjs = dayjs.utc(start);
    const newEndDate: dayjs.Dayjs = dayjs.utc(end).endOf("day");
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setEndDateString(dayjs.tz(newEndDate, timeZone).utc().format("YYYY-MM-DD"));

    updateFilters(newStartDate, newEndDate, Search, hubSelected);
  }

  function updateSearchField(e: any) {
    const value = e.target.value;
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(function () {
      updateFilters(startDate, endDate, value, hubSelected);
    }, 500);
  }

  const updateHubsField = (event: SelectChangeEvent<typeof hubSelected>) => {
    const {
      target: { value },
    } = event;

    const hubsSelected = typeof value === "string" ? value.split(",") : value;
    setHubSelected(hubsSelected);
    updateFilters(startDate, endDate, Search, hubsSelected);
  };

  function updateFilters(
    start: dayjs.Dayjs,
    end: dayjs.Dayjs,
    search: string,
    hubs: string[]
  ) {
    props.updateGrid(start, end, search, hubs);
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        position: "sticky",
        zIndex: "1100",
        width: "100%",
        margin: "auto",
      }}
    >
      <Grid item={true} xs={12}>
        <Card>
          <Grid
            container
            sx={{
              paddingLeft: "16px",
              background: "#2E2D38",
              paddingTop: "8px",
              paddingBottom: "8px",
              "& .MuiGrid-item": {
                marginRight: "10px",
              },
            }}
          >
            <Grid item>
              <TextField
                onChange={(evt: any) => updateSearchField(evt)}
                defaultValue={Search}
                placeholder="Search a feed..."
                style={{ width: "320px", height: "40px!important" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item>
              <DateRangePicker
                start={startDate.format()}
                end={endDateString}
                handleChange={updateFilterDate}
              />
            </Grid>
            <Grid>
              <FormControl sx={{ width: 150 }}>
                <InputLabel id="hubs-checkbox-label">Hubs</InputLabel>
                <Select
                  labelId="hubs-checkbox-label"
                  id="hubs-checkbox"
                  multiple
                  value={hubSelected}
                  onChange={updateHubsField}
                  input={<OutlinedInput label="Hubs" />}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {hubs.map((hub) => (
                    <MenuItem key={hub} value={hub}>
                      <Checkbox
                        checked={hubSelected.includes(hub)}
                        sx={{
                          "&.Mui-checked": {
                            color: "#141b4d",
                          },
                        }}
                      />
                      <ListItemText primary={hub} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Box>
  );
};
export default Menu;
