import axios from "axios";

export const epgService = {
  getImage,
};

async function getImage(sportId: number | null): Promise<string> {
  return await axios
    .post(
      `${process.env.REACT_APP_URL_LIAZ}${process.env.REACT_APP_EPG_PICTURE}`,
      { sportId: sportId !== null && sportId !== undefined ? sportId : 0 }
    )
    .then((res) => {
      if (res.data.picture169 === "") {
        return process.env.REACT_APP_DEFAULT_IMAGE;
      } else return res.data.picture169;
    })
    .catch((err: any) => {
      console.log(err);
      return "";
    });
}
