import axios from "axios";
import { IFeedValue, IFeedDetail } from "../../models/Feeds";
import { IResponse } from "../../Common";
import { useUserOlefStore } from "../stores/user.store";

export const olefService = {
  getFeeds,
  getFeedDetails,
};

async function getFeeds(
  startdate: Date,
  enddate: Date,
  provider: string | null,
  hubs: string[]
): Promise<IResponse> {
  const data = { startdate, enddate, provider, hubs };

  var result: IResponse = { callSuccess: false, result: null, error: null };
  if (process.env.REACT_APP_URL_LIAZ && process.env.REACT_APP_FEED_LIST) {
    const url: string =
      process.env.REACT_APP_URL_LIAZ + process.env.REACT_APP_FEED_LIST;

    await axios
      .post(url, data)
      .then((res) => {
        if (res.data && res.data.feeds) {
          var feedValues = new Array<IFeedValue>();
          res.data.feeds.map((feeditem: any) => {
            var feed: IFeedValue = JSON.parse(feeditem.FeedValue) as IFeedValue;
            feedValues.push(feed);

            return true;
          });
          result = { callSuccess: true, result: feedValues } as IResponse;
        }
      })
      .catch((err: any) => {
        console.log(err);
        result = { callSuccess: false, result: null, error: err } as IResponse;
      });
  }
  return result;
}

async function getFeedDetails(id: string): Promise<IResponse> {
  const data = { id };
  const canSeeAll: boolean = useUserOlefStore.getState()!.canSeeAll;

  let result: IResponse = { callSuccess: false, result: null, error: null };

  if (process.env.REACT_APP_URL_LIAZ && process.env.REACT_APP_FEED_DETAIL) {
    const url: string =
      process.env.REACT_APP_URL_LIAZ + process.env.REACT_APP_FEED_DETAIL;

    await axios
      .post(url, data)
      .then((res) => {
        if (res.data) {
          let services: IFeedDetail[] = JSON.parse(res.data.ServicesValue)
            .services as IFeedDetail[];
          services = services.filter(
            (serv: any) =>
              canSeeAll ||
              serv.description.includes("Input") ||
              serv.description.includes("Output")
          );
          result = { callSuccess: true, result: services } as IResponse;
        }
      })
      .catch((err: any) => {
        result = { callSuccess: false, result: null, error: err } as IResponse;
      });
  }
  return result;
}
